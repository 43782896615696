import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Button, Container, Drawer, IconButton, List, ListItemButton, ListSubheader } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export const NavBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
  
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{
            backgroundColor: '#000',
            borderBottom: '1px solid #333'
        }}>
        <Container>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }} component={Link} to="/">
              <img src={process.env.PUBLIC_URL + '/images/logo.webp'} style={{
                maxWidth: '150px',
                margin: '10px',
              }} 
              alt="Endevica Bio" />
            </Typography>
            
            { /** Mobile Menu  */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'none' }, justifyContent: 'flex-end'}} >
                <IconButton
                    size="large"
                    aria-label=""
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                    >
                    <MenuIcon />
                </IconButton>

                <React.Fragment>
                    <Drawer
                        anchor={"right"}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        style={{
                            minWidth: '250px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100vh',
                        }}
                        >
                        <List style={{
                            backgroundColor: "#000",
                            color: '#fff',
                            borderLeft: '1px solid #333',
                        }}>
                            <ListSubheader
                                style={{
                                    backgroundColor: '#000',
                                    color: "#60d9f9",
                                    textAlign: 'center',
                                    borderBottom: '1px solid #fff6',
                                    fontSize: '1.1rem'
                                }}
                                >Endevica Bio</ListSubheader>
                            { MenuItems.map(item => (
                                <ListItemButton 
                                    component={Link} to={item.path} 
                                    style={{
                                        minWidth: '250px',
                                        borderBottom: '1px solid #60d9f933',
                                
                                    }} onClick={handleCloseNavMenu} key={item.name}>
                                    { item.name }
                                </ListItemButton>
                            ))}
                        </List>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: "#000",
                            flex: '1',
                            borderLeft: '1px solid #333',
                        }}>
                            <img src={process.env.PUBLIC_URL + "/images/ICON.svg"} 
                                alt="Endevica Bio"
                                style={{
                                    height: '100px',
                                }} 
                                className="rotateCell"/>
                        </div>
                       
                    </Drawer>
                </React.Fragment>
                { /* 
                <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
            >
                    { MenuItems.map(item => (
                        <Link to={item.path} style={{textDecoration: 'none', color: '#000'}} className="noSelect" key={item.name} onClick={handleCloseNavMenu}>
                            <MenuItem style={{display: 'flex', justifyContent: 'center', minWidth: '150px'}}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>{ item.name }</div>
                            </MenuItem>
                        </Link>
                    ))}
                </Menu>
            */}


            </Box>
            
            { /** Desktop Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'right' }}>
                { MenuItems.map(item => (
                    <Button color="inherit" to={item.path} component={Link} key={item.name} className='menuItem'>{ item.name }</Button>
                ))}
            </Box>

          </Toolbar>
          </Container>
        </AppBar>
      </Box>
    );
}

const MenuItems = [
    {name: "Synthetic Peptides", path: "/synthetic-peptides"},
    {name: "Melanocortin System", path: "/melanocortin-system"},
    {name: "Weight Gain", path: "/weight-gain"},
    {name: "Weight Loss", path: "/weight-loss"},
    {name: "Contact", path: "/contact"},
]