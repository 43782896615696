import { Helmet } from "react-helmet"

export const HomeHelmet = () => (
    <>
        <Helmet>
            <link rel="canonical" href="https://endevicabio.com/" />
            <title>Endevica Bio - First in Class Therapeutic for Cachexia</title>
        </Helmet>
    </>
)


export const ContactHelmet = () => (
    <>
        <Helmet>
            <link rel="canonical" href="https://endevicabio.com/contact" />
            <title>Endevica Bio - Contact</title>
        </Helmet>
    </>
)

export const MelanocortinSystemHelmet = () => (
    <>
        <Helmet>
            <link rel="canonical" href="https://endevicabio.com/melanocortin-system" />
            <title>Endevica Bio - Melanocortin System</title>
        </Helmet>
    </>
)

export const SyntheticPeptidesHelmet = () => (
    <>
        <Helmet>
            <link rel="canonical" href="https://endevicabio.com/synthetic-peptides" />
            <title>Endevica Bio - Synthetic Peptides</title>
        </Helmet>
    </>
)

export const WeightGainHelmet = () => (
    <>
        <Helmet>
            <link rel="canonical" href="https://endevicabio.com/weight-gain" />
            <title>Endevica Bio - Weight Gain</title>
        </Helmet>
    </>
)

export const WeightLossHelmet = () => (
    <>
        <Helmet>
            <link rel="canonical" href="https://endevicabio.com/weight-loss" />
            <title>Endevica Bio - Weight Loss</title>
        </Helmet>
    </>
)
