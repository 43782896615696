import { Container, Grid } from "@mui/material"
import { useEffect } from "react"
import { Fade } from "react-reveal"
import { ScrollToTop } from "../components/ScrollTop"
import { HomeHelmet } from "../components/HelmetTags"

export const HomePage = () => {

    useEffect(() => {
        ScrollToTop()
    })

    const styles = {
        content: {
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: "9!important",
            overflowY: 'hidden',
        }
    }

    return (
        <div>
            <HomeHelmet />
            <div style={styles.content}>
                <div style={{
                    position: 'fixed',
                    height: '100vh',
                    width: '100%',
                    zIndex: "-1",
                    overflow: 'hidden!important'
                }}>
                    <video autoPlay loop id="bgVideo" muted playsInline={true}>
                        <source src={process.env.PUBLIC_URL + "images/out.webm"} type="video/webm" />
                    </video>
                </div>
                <Container style={{zIndex: "9"}}>
                    <Fade>
                        <Grid container style={{paddingTop: "200px", paddingBottom: '500px'}}>
                            <Grid item xs={12} md={5} style={{
                                padding: '15px',
                                backgroundColor: '#000e',
                                borderRadius: '0 10px 0px 10px',
                                boxShadow: '0 0 2px 3px #333',
                            }}>
                                <h2>Creating Novel Synthetic Peptidomimetics</h2>
                                <p style={{
                                    lineHeight: '1.8rem'
                                }}>
                                    Endevica's peptide designers create new molecules that will change standards of care for millions of patients worldwide.  Our peptides act as signaling molecules that can affect complex biological processes in novel but predictable ways, opening previously closed therapeutic pathways.
                                </p>
                            </Grid>
                        </Grid>
                    </Fade>
                </Container>
            </div>
        </div>
    )
}