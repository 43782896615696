import { Container } from "@mui/material"


export const MissingPage = () => {
    return (
        <div>
            <Container style={{
                backgroundColor: '#000',
                color: '#fff',
                minHeight: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <h2 style={{

                }}>404 - Sorry, this page was not found.</h2>
            </Container>
        </div>
    )
}