import { Container, Grid } from "@mui/material"
import { Fade } from "react-reveal"
import { ScrollToTop } from "../components/ScrollTop"
import { useEffect } from "react"
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { WeightLossHelmet } from "../components/HelmetTags";


export const WeightLossPage = () => {
    useEffect(() => {
        ScrollToTop()
    })
    return (
        <div>
            <WeightLossHelmet />
            <Container className="blackContainer">
                <Grid container style={{paddingTop: "40px"}}>
                    <Grid item xs={12} md={12}>
                        <Fade>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: '20px'
                        }}>
                            <h2>Melanocortin 4 Receptor Agonists As Weight Loss Drugs</h2>
                            <img src={process.env.PUBLIC_URL + 'images/weightloss.webp'} alt="weight loss" style={{
                                maxWidth: '100%'
                            }} />
                            <div style={{padding: '10px',textAlign: 'center',
                                backgroundColor: '#000', border: '1px solid #333', marginBottom: '40px'}}>
                                Internal Data
                            </div>
                        </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={8} style={{
                        padding: '15px',
                    }}>
                        <Fade><>
                        <p className="extendedLineHeight"  
                            style={{
                                textAlign: "justify"
                            }}>
                            <b>Obesity</b> is a major public health crisis in the United States; over 40% of American adults qualify as obese. Obesity significantly increases the risk of developing chronic diseases like heart disease, type 2 diabetes, and some cancers. GLP-1 agonists, have revolutionized obesity treatment, with the ability to reduce body weight up to 20.9% over 72 weeks 
                            (<a title="https://jamanetwork.com/journals/jama/fullarticle/2812936" href="https://jamanetwork.com/journals/jama/fullarticle/2812936" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://jamanetwork.com/journals/jama/fullarticle/2812936&amp;source=gmail&amp;ust=1711636210248000&amp;usg=AOvVaw1k0eK3S_k8DvTwFMjpWj47">Aronne et al. 2024</a>).
                        </p>
                        </></Fade>
                        <Fade><>
                        <p className="extendedLineHeight"  
                            style={{
                                textAlign: "justify"
                            }}>
                            However, there are a number of issues with GLP-1s.  For example, the efficacy of GLP-1's plateau over time, patients experience large rebounds in weight after coming off the GLP-1’s, and there are tolerability issues for GLP-1’s, including nausea and vomiting.  These issues highlight the need for new modality weight loss drugs. 
                        </p>
                        </></Fade>
                        <Fade><>
                        <p className="extendedLineHeight"  
                            style={{
                                textAlign: "justify"
                            }}>
                            At Endevica Bio, we have developed melanocortin-4 receptor (MC4R) agonists that match the weight loss efficacy of GLP-1's in rats and that do not result in large rebounds in weight after removal of drug (See data below). We are finalizing preclinical development of our lead compound and are preparing for a phase 1 clinical trial.
                        </p>
                        </></Fade>
                        <Fade><>
                        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingTop: '50px'}}>
                            <img src={process.env.PUBLIC_URL + 'images/weightloss2.webp'} alt="weight loss" style={{
                                width: '350px', height: 'auto', paddingBottom: '25px', maxWidth: '100%'
                            }} />
                            <div style={{padding: '10px',textAlign: 'center',
                                backgroundColor: '#000', border: '1px solid #333', marginBottom: '40px', width: '250px'}}>
                                Internal Data
                            </div>
                        </div>
                        </></Fade>
                    </Grid>
                    <Grid item xs={12} md={4} style={{
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                            lineHeight: '2rem'
                        }}>
                        <Fade>
                        <div>
                            <FormatQuoteIcon className="blueText imageFlip"/>
                            At Endevica Bio, we have developed melanocortin-4 receptor (MC4R) agonists that match the weight loss efficacy of GLP-1's in rats...
                            <FormatQuoteIcon className="blueText"/>
                        </div>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}