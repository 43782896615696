import { Button, Container, Grid, Paper, Snackbar, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { Fade } from "react-reveal"
import { ScrollToTop } from "../components/ScrollTop"
import LoopIcon from '@mui/icons-material/Loop';
import { ContactHelmet } from "../components/HelmetTags";

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const ContactUsPage = () => {
    const [ email, setEmail ] = useState("")
    const [ name, setName ] = useState("")
    const [ message, setMessage ] = useState("")
    
    // disable
    const [ sending, setSending ] = useState(false)

    const sendMessage = async () => {
        if (name === "") {
            setAlertMessage("Name Required")
            setOpen(true)
            return 
        } else if (email === "") {
            setAlertMessage("Email Required")
            setOpen(true)
            return
        } else if (message === "") {
            setAlertMessage("Message Required")
            setOpen(true)
            return
        } else if (!validateEmail(email)) {
            setAlertMessage("Invalid Email")
            setOpen(true)
            return
        }


        setSending(true)

        // call api here 
        await fetch(`/api/contact`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( 
                {
                    name: name,
                    email: email,
                    message: message
                }
            )
        }).then(res => res.json()).catch(err => {
            console.log(err)
            setSending(false)
            return
        })

        setSending(false)
        setAlertMessage("Message Sent")
        setOpen(true)

        setEmail("")
        setName("")
        setMessage("")
    }

    const onChangeEmail = (e) => setEmail(e.target.value)
    const onChangeName = (e) => setName(e.target.value)
    const onChangeMessage = (e) => setMessage(e.target.value)

    const [ open, setOpen ] = useState(false)
    const [ alertMessage, setAlertMessage ] = useState("Message Sent")
    const handleClose = (event) => {
        setOpen(false);
    };

    useEffect(() => {
        ScrollToTop()
    }, [])

    const customStyle = {
        InputLabelProps: {
            style: {
                color: '#fff',
            }
        },
        inputProps:{
            style: {
                borderRadius: '5px',
                color: '#fff',
                backgroundColor: '#333',
            },
            className: "greyBg"
        }
    }

    return (
        <div>
            <ContactHelmet />
            <Container className="blackContainer" style={{paddingTop: '50px', paddingBottom: '250px'}} >
                <Fade>
                    <Grid container>
                        <Grid item xs={12} md={6} style={{
                            padding: '15px',
                            textAlign: 'justify',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Paper style={{
                                padding: "5px 20px 20px 20px",
                                backgroundColor: '#111',
                                color: '#fff',
                                border: '1px solid #60d9f977'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center'
                                }}>
                                    <h2>Contact Endevica <span className="blueText">Bio</span></h2>
                                    { sending && (
                                        <LoopIcon 
                                        className="rotateSend"
                                        style={{
                                            width: '50px',
                                            height: '50px'
                                        }}/>
                                    )}
                                </div>
                                <TextField 
                                { ...customStyle}
                                placeholder="Name" label="Name" fullWidth style={{marginBottom: '10px'}} value={name} onChange={onChangeName} />
                                <TextField 
                                { ...customStyle}
                                placeholder="Email" label="Email" fullWidth style={{marginBottom: '10px'}} value={email} onChange={onChangeEmail} />
                                <TextField 
                                { ...customStyle}
                                placeholder="Message" label="Message" rows={3} multiline fullWidth style={{marginBottom: '10px'}} value={message} onChange={onChangeMessage} />
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <Button variant="contained" disabled={sending} color="primary" style={{minWidth: '150px'}} onClick={sendMessage}>Send</Button>
                                </div>
                            </Paper>

                        </Grid>
                        <Grid item xs={12} md={6} style={{
                            padding: '15px',
                            textAlign: 'justify'
                        }}>
                            <h2>Directions</h2>
                            <div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d47353.814329313325!2d-87.826418!3d42.115750000000006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fc717935573bb%3A0x3b967fd565e3d9b2!2s1935%20Techny%20Rd%20%2314%2C%20Northbrook%2C%20IL%2060062!5e0!3m2!1sen!2sus!4v1713291908397!5m2!1sen!2sus" 
                                style={{width: '100%', height: '300px',border: '0'}} title="Map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div style={{paddingTop: '15px'}}>
                                <div style={{padding: '5px', color: '#2c8dbc', fontSize: '1.3rem'}}>Endevica Bio</div>
                                <div style={{padding: '5px'}}>1935 Techny Rd, Suite 14</div>
                                <div style={{padding: '5px'}}>Northbrook, IL 60062 USA</div>
                                <div style={{padding: '5px'}}>E-mail: <a href="mailto:info@endevicabio.com" rel="noreferrer" target="_blank" 
                                    style={{
                                        color: '#2c8dbc'
                                    }}>info@endevicabio.com</a></div>
                            </div>
                        </Grid>
                    </Grid>
                </Fade>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={alertMessage}
                />
            </Container>
        </div>
    )
}