import { Button, Container, Grid } from "@mui/material"
import { Link } from "react-router-dom"
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const FooterComponent = () => {

    return (
        <div style={{
            borderTop: '1px solid #333',
            backgroundColor: '#000',
            color: "#fff",
            paddingBottom: '50px',
            zIndex: 9,
            display: 'flex',
            flexDirection: "column",
            overflowY: 'hidden'
        }}>
            <Container style={{
                padding: '20px',
            }}>
                <Grid container>
                    <Grid item xs={12} md={6} style={{padding: '10px'}}
                        order={{ xs: 1, sm: 0 }}>
                        <div>
                            <div style={{padding: '5px', color: '#2c8dbc', fontSize: '1.3rem'}}>Endevica Bio</div>
                            <div style={{padding: '5px'}}>1935 Techny Rd, Suite 14</div>
                            <div style={{padding: '5px'}}>Northbrook, IL 60062 USA</div>
                            <div style={{padding: '5px'}}>E-mail: <a href="mailto:info@endevicabio.com" rel="noreferrer" target="_blank" 
                                style={{
                                    color: '#2c8dbc'
                                }}>info@endevicabio.com</a></div>
                        </div>
                        <div style={{
                            padding: '10px'
                        }}>
                            <a href="https://www.linkedin.com/company/endevicabio" target="blank" rel="noreferrer">
                                <LinkedInIcon style={{
                                    color: '#0a66c2',
                                    width: '50px',
                                    height: '50px'
                                }} />
                            </a>
                        </div>
                        <div
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px'}}
                            >Copyright &copy; { new Date().getFullYear() } Endevica Bio</div>
                    </Grid>
                    <Grid item xs={12} md={6} style={{textAlign: 'center'}}
                        order={{ xs: 0, sm: 1 }}>
                        <div>
                            { MenuItems.map(item => (
                                <div
                                    key={item.name} 
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '5px',
                                        color: '#fff'
                                    }}>
                                    <Button 
                                        style={{
                                            color: '#fff'
                                        }}
                                        variant="outlined"
                                        LinkComponent={Link} to={item.path}>{item.name}</Button>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <div style={{flex: "1", backgroundColor: '#000'}}>

            </div>
        </div>
    )
}

const MenuItems = [
    {name: "Synthetic Peptides", path: "/synthetic-peptides"},
    {name: "Melanocortin System", path: "/melanocortin-system"},
    {name: "Weight Gain", path: "/weight-gain"},
    {name: "Weight Loss", path: "/weight-loss"},
    {name: "Contact", path: "/contact"},
]