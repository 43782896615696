import { Container, Grid } from "@mui/material"
import { Fade } from "react-reveal"
import { ScrollToTop } from "../components/ScrollTop"
import { useEffect } from "react"
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { SyntheticPeptidesHelmet } from "../components/HelmetTags";

export const SyntheticPeptidesPage = () => {
    useEffect(() => {
        ScrollToTop()
    })

    return (
        <div>
            <SyntheticPeptidesHelmet />
            <Container className="blackContainer">
                <Grid container style={{paddingTop: "40px"}}>
                    <Grid item xs={12} md={8} style={{
                        padding: '15px',
                    }}>
                        <Fade><>
                        <h2>Synthetic Peptides</h2>
                        <img src={process.env.PUBLIC_URL + 'images/syntheticpeptides.webp'} alt="synthetic peptides" style={{
                            maxWidth: '100%'
                        }} />
                        </></Fade>
                        <Fade><>
                        <div style={{padding: '10px',textAlign: 'center',
                            backgroundColor: '#000', border: '1px solid #333', marginBottom: '40px'}} 
                            className="extendedLineHeight">
                            Binding of a small molecule compared with a cyclic peptidomimetic to the melanocortin-4 receptor. Peptides fit well into the binding pocket and have many, key stabilizing interactions with the receptor.
                        </div>
                        </></Fade>
                        <Fade><>
                        <p 
                            className="extendedLineHeight" style={{
                            textAlign: "justify"
                        }}>
                            <b>Throughout history</b>, certain receptors in the human body, such as the melanocortin receptors, have evolved to bind with peptides. These peptide receptors are part of signaling networks that regulate many biological functions.  For example, the melanocortin receptors play a pivotal role in body weight and energy homeostasis. The specificity and affinity of these receptors for peptide ligands stem from complex chemistries, which small molecules and monoclonal antibodies often cannot fully replicate. 
                        </p>
                        </></Fade>
                        <Fade><>
                        <p 
                            className="extendedLineHeight" style={{
                            textAlign: "justify"
                        }}>
                            The necessity for peptide-based drugs arises from the unique characteristics of these receptor-ligand interactions. Small molecule drugs, while advantageous due to their stability and oral bioavailability, often lack the specificity to effectively mimic or modulate the actions of endogenous peptides due to size and structural constraints. Monoclonal antibodies, on the other hand, offer high specificity but are complex to develop, costly and can evoke undesired immune responses. Peptide drugs, with their structural similarity to endogenous ligands, are uniquely positioned to interact with these receptors with high specificity, minimizing off-target effects and maximizing therapeutic potential.
                        </p>
                        </></Fade>
                        <Fade><>
                        <p 
                            className="extendedLineHeight" style={{
                            textAlign: "justify"
                        }}>
                            Endevica Bio has designed peptides with enhanced stability, potency, and bioavailability for several, undisclosed receptors, which has yielded lead compounds with preclinical efficacy across numerous indications. 
                        </p>
                        </></Fade>
                    </Grid>


                    <Grid item xs={12} md={4} style={{
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: '20px',
                            lineHeight: '2rem'
                        }}>
                        <Fade><>
                        <div>
                            <FormatQuoteIcon className="blueText imageFlip"/>
                            ...The necessity for peptide-based drugs arises from the unique characteristics of these receptor-ligand interactions.
                            <FormatQuoteIcon className="blueText"/>
                        </div>
                        <div style={{paddingTop: '100px', paddingBottom: '50px', display: "flex", justifyContent: "center"}} className="hoverScale">
                            <img src={process.env.PUBLIC_URL + "/images/ICON.svg"} 
                                alt="Endevica Bio"
                                style={{
                                    height: '100px',
                                }} 
                                className="rotate"/>
                        </div>
                        </></Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}