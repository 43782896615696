import { Fade } from "@mui/material"
import {  useEffect, useState } from "react"


export const SplashScreen = ({children}) => {
    const [ fade, setFade ] = useState(false)

    useEffect(() => {
        setTimeout(() => setFade(true), 2800)
    })

    return (
        <div>
            { /* After Splash */}
            { fade && (
                <Fade in={fade}>
                    <div>
                        <div>{children}</div>
                    </div>
                </Fade>
            )}

            { /* Before Splash */}
            { !fade && (
                <Fade in={!fade}>
                    <div style={{
                        height: '100vh',
                        width: '100vw',
                        backgroundColor: '#000',
                    }}>
                        { /**
                        <div className="splashTransition">
                            <img src={process.env.PUBLIC_URL + "/images/cell.webp"} alt="cell" />
                        </div>
                        */}
                        <div className="splashLogo">
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="logoRotate">
                                <img src={process.env.PUBLIC_URL + "/images/ICON.svg"} style={{maxWidth: "100px"}} 
                                    alt="Endevica Bio" />
                            </div>
                            <h1>ENDEVICA BIO</h1>
                        </div>
                    </div>
                </Fade>
            )}
        </div>
    )
}
