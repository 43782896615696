import { Container, Grid } from "@mui/material"
import { Fade } from "react-reveal"
import { ScrollToTop } from "../components/ScrollTop"
import { useEffect } from "react"
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { MelanocortinSystemHelmet } from "../components/HelmetTags";


export const MelanocortinSystemPage = () => {

    useEffect(() => {
        ScrollToTop()
    })

    return (
        <div>
            <MelanocortinSystemHelmet />
            <Container className="blackContainer">
                <Grid container style={{paddingTop: "40px"}}>
                    <Grid item xs={12} md={8} style={{
                        padding: '15px',
                    }}>
                        <div>
                            <Fade><>
                            <h2>Melanocortin System</h2>
                            <img src={process.env.PUBLIC_URL + 'images/melanocortinsystem.webp'} alt="melanocortin system" style={{
                                maxWidth: '100%'
                            }} />
                            <p 
                                className="extendedLineHeight"
                                style={{
                                textAlign: "justify"
                            }}>
                                The melanocortin system plays a pivotal role in regulating body weight through a complex network of hormones, receptors, and neuronal pathways. Of the melanocortin receptors, melanocortin-4 receptor (MC4R) is particularly significant in controlling satiety and energy expenditure.  In mammals, MC4R activity is modulated by the endogenous peptide ligands α-MSH (agonist) and AgRP (antagonist). Activation of MC4R in the hypothalamus, a brain region critical for hunger and energy balance, leads to reduced food intake, increased metabolism, and weight loss.  Conversely, antagonization of MC4R results in increased food intake, slowed metabolism, and weight gain.
                            </p>
                            </></Fade>
                            <Fade><>
                            <p 
                                className="extendedLineHeight"
                                style={{
                                    textAlign: "justify"
                                }}>
                                Historically, MC4R has been recognized as a drug target for over 20 years.  However, it has proved a difficult receptor for drug developers to target.  Multiple small molecule attempts to modulate this system have failed.  At Endevica Bio, we understand that MC4R has evolved to bind peptide ligands; we have devised libraries of peptides that potently agonize and antagonize MC4R.  These peptides demonstrate preclinical efficacy to bidirectionally modulate weight in rats, and they avoid known cardiotoxicity of MC4R peptide ligands associated with off-target effects on RFamide receptors.
                            </p>
                            </></Fade>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} style={{
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                            lineHeight: '2rem'
                        }}>
                        <div>
                            <Fade><>
                            <div style={{paddingTop: '100px', paddingBottom: '50px', display: 'flex', justifyContent: 'center'}}>
                                <img src={process.env.PUBLIC_URL + "/images/cell.webp"} 
                                    alt="Endevica Bio"
                                    style={{
                                        height: '100px',
                                    }} 
                                    className="rotateCell"/>
                            </div>
                            <FormatQuoteIcon className="blueText imageFlip"/>
                            ...antagonization of MC4R results in increased food intake, slowed metabolism, and weight gain.
                            <FormatQuoteIcon className="blueText"/>
                            </></Fade>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}