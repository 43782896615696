import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { HomePage } from './pages/Home';
import { SyntheticPeptidesPage } from './pages/SyntheticPeptides';
import { MelanocortinSystemPage } from './pages/MelanocortinSystem';
import { WeightGainPage } from './pages/WeightGain';
import { WeightLossPage } from './pages/WeightLoss';
import { NavBar } from './components/Navbar';
import { FooterComponent } from './components/Footer';
import { ContactUsPage } from './pages/ContactUs';
import { SplashScreen } from './components/SplashScreen';
import { MissingPage } from './pages/404';

function App() {
  return (
    <SplashScreen>
      <div id="topPage">
        <BrowserRouter>
        <AppBG>
        <NavBar />
          <div>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/synthetic-peptides" element={<SyntheticPeptidesPage />} />
              <Route path="/melanocortin-system" element={<MelanocortinSystemPage />} />
              <Route path="/weight-gain" element={<WeightGainPage />} />
              <Route path="/weight-loss" element={<WeightLossPage />} />
              <Route path="/contact" element={<ContactUsPage />} />

              <Route path="*" element={<MissingPage />} />
            </Routes>
          </div>
        <FooterComponent />
        </AppBG>
        </BrowserRouter>
      </div>
    </SplashScreen>
  );
}

const AppBG = ({children}) => {

  const path = useLocation();
  return (
    <div className={path.pathname !== "/" ? 'waveBackground' : ""}>
      { children }
    </div>
  )
}

export default App;
