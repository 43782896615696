import { Container, Grid } from "@mui/material"
import { Fade } from "react-reveal"
import { ScrollToTop } from "../components/ScrollTop"
import { useEffect } from "react"
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { WeightGainHelmet } from "../components/HelmetTags";


export const WeightGainPage = () => {
    useEffect(() => {
        ScrollToTop()
    })
    return (
        <div>
            <WeightGainHelmet />
            <Container className="blackContainer">
                <Grid container style={{paddingTop: "40px"}}>
                    <Grid item xs={12} md={12}>
                        <Fade><>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: '20px'
                        }}>
                            <h2>Weight Gain</h2>
                            <img src={process.env.PUBLIC_URL + 'images/weightgain.webp'} alt="weight gain" style={{
                                maxWidth: '100%'
                            }} />
                            <div style={{padding: '10px',textAlign: 'center',
                                backgroundColor: '#000', border: '1px solid #333', marginBottom: '40px'}}>
                                Internal Data
                            </div>
                        </div>
                        </></Fade>
                    </Grid>
                    <Grid item xs={12} md={8} style={{
                        padding: '15px',
                    }}>
                        <Fade><>
                        <p 
                        className="extendedLineHeight"
                        style={{
                            textAlign: "justify"
                        }}>
                            <b>Uncontrolled weight loss</b> in cancer patients is a complex wasting syndrome characterized by anorexia, an involuntary loss of 
                            adipose tissue (AT) and lean body mass (LBM), and a paradoxical increase in energy catabolism. This loss of body weight, which differs 
                            from that in starvation, is disproportionate to reduced food intake, and cannot be fully reversed by conventional nutritional support 
                            (<a title="https://pubmed.ncbi.nlm.nih.gov/21296615/" href="https://pubmed.ncbi.nlm.nih.gov/21296615/" target="_blank" rel="noreferrer" 
                                data-saferedirecturl="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/21296615/&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw2rxNbT0r90XnSc5MFQJSQ6">Fearon et al, 2011</a>). 
                                This uncontrolled weight loss is also associated with pathological conditions, such as untreated cancer, chronic kidney disease (CKD), sepsis, 
                                chronic obstructive pulmonary disease, congestive heart failure, and acquired immunodeficiency syndrome. The severity of weight loss in these 
                                illnesses is often the primary determinant for morbidity, reduced quality of life (QoL) and eventual mortality.
                        </p>
                        </></Fade>
                        <Fade><>
                        <p 
                            className="extendedLineHeight"  
                            style={{
                                textAlign: "justify"
                            }}>
                            Uncontrolled weight loss impacts approximately 60% of the 1.4 million patients diagnosed with cancer each year in the United States (US). 
                            Among late-stage cancer patients, uncontrolled weight loss affects 50 to 80% of patients and is responsible for 20 to 40% of cancer 
                            related deaths (
                                <a title="https://pubmed.ncbi.nlm.nih.gov/17285641/" href="https://pubmed.ncbi.nlm.nih.gov/17285641/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/17285641/&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw0g2IG72ULxmx39tRgfpDR4">Couch&nbsp;et al, 2007</a>
                            ). The prevalence of uncontrolled weight loss varies with cancer type, ranging from 50% in 
                            patients with advanced head and neck cancer to 60% and 80% in patients with lung cancer and pancreatic cancer, respectively 
                            (
                                <a title="https://pubmed.ncbi.nlm.nih.gov/10763643/" href="https://pubmed.ncbi.nlm.nih.gov/10763643/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/10763643/&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw3DwoyGKbG2LMsHKONDbiZ7"> Lees, 1999</a>
                                ; 
                                <a title="https://pubmed.ncbi.nlm.nih.gov/8561819/" href="https://pubmed.ncbi.nlm.nih.gov/8561819/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/8561819/&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw2MnTzGOL5D1UjTY90cu8Ak"> Buntzel and Küttner,1995</a>
                                ; 
                                <a title="https://pubmed.ncbi.nlm.nih.gov/12415256/" href="https://pubmed.ncbi.nlm.nih.gov/12415256/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/12415256/&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw1n0mjhlfXFFSVSJdfxJ0HH"> Tisdale, 2002</a>
                                ; 
                                <a href="https://pubmed.ncbi.nlm.nih.gov/17285641/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/17285641/&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw0g2IG72ULxmx39tRgfpDR4"> Couch et al, 2007</a>
                                ). Additionally, patients with uncontrolled weight loss suffer 
                            from a multisystem syndrome characterized by anorexia, sarcopenia, anemia, asthenia, systemic inflammation, insulin resistance, and 
                            emotional and mental fatigue, all of which cause a general decline in a patient's functional status and quality of life. Cancer prognosis 
                            is adversely affected in patients with uncontrolled weight loss, and response to chemotherapy may be diminished and accompanied by increased 
                            complications and toxicities.
                        </p>
                        </></Fade>
                        <Fade><>
                        <p 
                            className="extendedLineHeight"  
                            style={{
                                textAlign: "justify"
                            }}>
                            At Endevica Bio, we are developing TCMCB07 (B07), a MC3R and MC4R antagonist, to treat uncontrolled weight loss in cancer.  
                            The potential of MCR antagonists to treat uncontrolled weight loss in cancer has been investigated for the last 2 decades 
                            Preclinical studies demonstrated elevated MC system activity mediated through MC4R to be critical to the initiation and maintenance 
                            of cachexia (
                                <a title="https://link.springer.com/chapter/10.1007/978-1-4615-0501-3_8" href="https://link.springer.com/chapter/10.1007/978-1-4615-0501-3_8" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://link.springer.com/chapter/10.1007/978-1-4615-0501-3_8&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw3SaM4x7vl8w8nxbih3Tmu4">Marks et al, 2003</a>
                            ), while nonclinical gene deletion experiments in mice showed that a functional MC4R 
                            is required for lipopolysaccharide-induced acute cachexia, cancer cachexia, and chronic kidney disease (CKD) cachexia 
                            (
                                <a href="https://pubmed.ncbi.nlm.nih.gov/20190196/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/20190196/&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw0N2uLUp6BYpv99s1AkQC-0">Tao, 2010</a>
                                ; 
                                <a title="https://pubmed.ncbi.nlm.nih.gov/17563464/" href="https://pubmed.ncbi.nlm.nih.gov/17563464/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/17563464/&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw0eCVsN3inQJd7ydVhQ4VXU"> DeBoer, 2007</a>
                                ; 
                                <a title="https://pubmed.ncbi.nlm.nih.gov/18713954/" href="https://pubmed.ncbi.nlm.nih.gov/18713954/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://pubmed.ncbi.nlm.nih.gov/18713954/&amp;source=gmail&amp;ust=1711573431671000&amp;usg=AOvVaw26YuNoH08ocu8dKc3g9ta9"> Laviano et al, 2008</a>
                                ).  B07 is a clinical stage drug that has proven effective at increasing 
                            body weight in various chemotherapy and CKD models.
                        </p>
                        </></Fade>
                        <Fade><>
                        <p className="extendedLineHeight"  
                            style={{
                                textAlign: "justify"
                            }}>
                            The data on this page demonstrate B07's ability to prevent rats from losing weight while undergoing chemotherapy treatment.
                        </p>
                        </></Fade>
                    </Grid>
                    <Grid item xs={12} md={4} style={{
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                            lineHeight: '2rem'
                        }}>
                        <Fade>
                        <div>
                            <FormatQuoteIcon className="blueText imageFlip"/>
                            ...Uncontrolled weight loss impacts approximately 60% of the 1.4 million patients diagnosed with cancer each year in the United States.
                            <FormatQuoteIcon className="blueText"/>
                        </div>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Fade>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            padding: '20px'
                        }}>
                            <img src={process.env.PUBLIC_URL + 'images/weightgain2.webp'} alt="weight gain" style={{
                                maxWidth: '100%'
                            }} />
                            <div style={{padding: '10px',textAlign: 'center',
                                backgroundColor: '#000', border: '1px solid #333', marginBottom: '40px'}}>
                                Internal Data
                            </div>
                        </div>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}